@import "~@angular/material/theming";
@import "./palettes";

// MEDIA QUERIES DEFAULT vvv
$tablet-width: 768px;
$desktop-width: 1440px;

$media-mobile: "only screen and (max-width : 799px)";
$media-tablet: "only screen and (min-width : 800px) and (max-width : 1439px)";
$media-tablet-desktop: "only screen and (min-width : 800px)";
$media-desktop: "only screen and (min-width : 1440px)";

$media-mobile-and-tablet: "only screen and (max-width : 1439)";
// MEDIA QUERIES DEFAULT ^^^

// COLORS //
$color-primary: mat-color($pal-primary);
$color-accent: mat-color($pal-accent);
$color-error: mat-color($pal-error);
$color-success: mat-color($pal-success);
$color-warning: mat-color($pal-warning);
$color-black: #000000;
$color-white: #ffffff;
$color-inactive: #cccccc;

$color-unipd: #9b0014;

// PRIMARY HUES
$color-primary-50: mat-color($pal-primary, 50);
$color-primary-100: mat-color($pal-primary, 100);
$color-primary-200: mat-color($pal-primary, 200);

// GREY
$color-grey-900: mat-color($pal-grey, 900);
$color-grey-800: mat-color($pal-grey, 800);
$color-grey-700: mat-color($pal-grey, 700);
$color-grey-600: mat-color($pal-grey, 600);
$color-grey-500: mat-color($pal-grey, 500);
$color-grey-400: mat-color($pal-grey, 400);
$color-grey-300: mat-color($pal-grey, 300);
$color-grey-200: mat-color($pal-grey, 200);
$color-grey-100: mat-color($pal-grey, 100);
$color-grey-50: mat-color($pal-grey, 50);

$color-grey-light-25: #f9fafa;
$color-grey-light-50: #c2c9d1;
$color-grey-dark-25: #91979d;
$color-grey-dark-50: #616568;

// ERROR HUES
$color-error-100: mat-color($pal-error, 100);


// SCROLLBAR
$scrollbar-color: #90a4ae;

// Z-INDEX
$z-index-menu: 10;
$z-index-topbar: 9;
$z-index-backdrop: 8;

// MENU
$menu-open-width: 260px;
$menu-close-width: 70px;
$menu-bg-color: $color-accent;
$menu-transition-time: 0.2s;
$menu-hover-color: rgba(255, 255, 255, 0.25);

// TOP-BAR
$top-bar-height: 80px;
$top-bar-height-mobile: 112px;

// FOOTER
$footer-height-desktop: 135px;
$footer-height-mobile: 162px;

$color-background: #FFF;
$main-container-width: 1200px;

* {
  margin: 0;
  padding: 0;
  border: 0 none;
  outline: 0 none;
  text-decoration: none;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html,
body,
:not(.mat-icon) {
  font-family: "Basier" !important;
  font-weight: 400;
}

body {
  height: 100%;
  overflow-x: hidden;
}

button:focus {
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  color: inherit;
}

a {
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

@font-face {
  font-family: Basier;
  font-weight: 700;
  src: url("/assets/fonts/BasierCircle-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Basier;
  src: url("/assets/fonts/BasierCircle-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Basier;
  src: url("/assets/fonts/BasierCircle-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Anton;
  src: url("/assets/fonts/Anton-Regular.ttf") format("truetype");
}

.h1 {
  font-size: 64px;
  line-height: 80px;
  font-weight: 500;
  letter-spacing: -2px;
}


.h2 {
  font-size: 54px;
  line-height: 63px;
  font-weight: 400;
  letter-spacing: -2px;
}


.h3 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 400;
  letter-spacing: -1px;
}


.h4 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  letter-spacing: -1px;
}


.h5 {
  font-size: 24px;
  line-height: 29px;
  font-weight: 400;
}


.h6 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}


.subtitle1 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}


.subtitle2 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}


.body1 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}


.body2 {
  font-size: 14px;
  line-height: 18px;
}

.button {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.caption {
  font-size: 10px;
  line-height: 14px;
}

.--fw500 {
  font-weight: 500 !important;
}

.--fw600 {
  font-weight: 600 !important;
}

.--fw700 {
  font-weight: 700 !important;
}

.--ff-anton {
  letter-spacing: 0.1em;
  font-family: Anton !important;
}

.--ls-02 {
  
  letter-spacing: 0.2em;
}

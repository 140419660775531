@import "~src/styles/partials/consts";

.square-spinner-loader {
  width: 100px;
  height: 100px;
  background-color: $color-primary;
  outline: 6px solid $color-primary;
  outline-offset: -1px;
  transform: rotate(45deg) scale(0.3);
}

.square-spinner-loader:before {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  background-color: $color-accent;
  -webkit-animation: rotate 3s infinite;
  animation: rotate 3s infinite;
}
@-webkit-keyframes rotate {
  0% {
    transform-origin: bottom left;
    transform: translate(-5px, -105px) rotate(0deg) scale(1.1);
  }
  25% {
    transform-origin: bottom left;
    transform: translate(-5px, -105px) rotate(-180deg) scale(1.1);
  }
  26% {
    transform-origin: top left;
    transform: translate(-5px, 105px) rotate(-180deg) scale(1.1);
  }
  50% {
    transform-origin: top left;
    transform: translate(-5px, 105px) rotate(-360deg) scale(1.1);
  }
  51% {
    transform-origin: top right;
    transform: translate(5px, 105px) rotate(-360deg) scale(1.1);
  }
  75% {
    transform-origin: top right;
    transform: translate(5px, 105px) rotate(-540deg) scale(1.1);
  }
  76% {
    transform-origin: bottom right;
    transform: translate(5px, -105px) rotate(-540deg) scale(1.1);
  }
  100% {
    transform-origin: bottom right;
    transform: translate(5px, -105px) rotate(-720deg) scale(1.1);
  }
}
@keyframes rotate {
  0% {
    transform-origin: bottom left;
    transform: translate(-5px, -105px) rotate(0deg) scale(1.1);
  }
  25% {
    transform-origin: bottom left;
    transform: translate(-5px, -105px) rotate(-180deg) scale(1.1);
  }
  26% {
    transform-origin: top left;
    transform: translate(-5px, 105px) rotate(-180deg) scale(1.1);
  }
  50% {
    transform-origin: top left;
    transform: translate(-5px, 105px) rotate(-360deg) scale(1.1);
  }
  51% {
    transform-origin: top right;
    transform: translate(5px, 105px) rotate(-360deg) scale(1.1);
  }
  75% {
    transform-origin: top right;
    transform: translate(5px, 105px) rotate(-540deg) scale(1.1);
  }
  76% {
    transform-origin: bottom right;
    transform: translate(5px, -105px) rotate(-540deg) scale(1.1);
  }
  100% {
    transform-origin: bottom right;
    transform: translate(5px, -105px) rotate(-720deg) scale(1.1);
  }
}

.color-primary {
  color: $color-primary !important;
}
.color-accent {
  color: $color-accent !important;
}
.color-error {
  color: $color-error !important;
}
.color-warning {
  color: $color-warning !important;
}
.color-success {
  color: $color-success !important;
}
.color-black {
  color: $color-black !important;
}
.color-white {
  color: $color-white !important;
}
.color-inactive {
  color: $color-inactive !important;
}

.color-primary-200 {
  color: $color-primary-200 !important;
}

.color-unipd {
  color: $color-unipd !important;
}

// .color-grey-dark-25 {
//   color: $color-grey-dark-25 !important;
// }
// .color-grey-dark-50 {
//   color: $color-grey-dark-50 !important;
// }
// .color-grey-light-25 {
//   color: $color-grey-light-25 !important;
// }
// .color-grey-light-50 {
//   color: $color-grey-light-50 !important;
// }

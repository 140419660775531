@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./partials/reset";
@import "./partials/ng-material";
@import "./partials/consts";
@import "./partials/colors";
@import "./partials/typography";
@import "./partials/material-override";

@import "./loaders/squares-spinner-loader";

// @import "./partials/mixins";

.cursor-pointer {
  cursor: pointer;
}

.main-container {
  width: 100%;
  max-width: $main-container-width;
  margin: 0 auto;
  padding: 0;
}

.upp-page {
  min-height: calc(100vh - #{$top-bar-height} - #{$footer-height-desktop});

  @include mobile {
    min-height: calc(
      100vh - #{$top-bar-height-mobile} - #{$footer-height-mobile}
    );
  }
}

.upp-link {
  text-decoration: underline;
  color: $color-primary;

  &:hover {
    text-decoration: underline;
  }
}

// fullcalendar - capitalize first letter of month and day name
.fc-toolbar,
.fc-col-header-cell-cushion {
  text-transform: capitalize;
}

// fullcalendar - hide today btn
.fc-today-button {
  display: none !important;
}

.fc-daygrid-day.fc-day-today {
  background-color: transparent !important;
}

.fc .fc-daygrid-day-number {
  z-index: 0 !important;
}

.long-calendar {
  height: 450px !important;
}

.admin-event {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  opacity: 0.6 !important;
  &:hover {
    opacity: 0.4 !important;
  }
  .fc-event-title {
    color: #000;
  }
}

@import "./mixins";

@import "./material/mat-button";
@import "./material/mat-checkbox";
@import "./material/mat-dialog";
@import "./material/mat-form-field";
@import "./material/mat-icon";
@import "./material/mat-select";
@import "./material/mat-snackbar";
@import "./material/mat-table";

.upp-stepper {
  .mat-horizontal-content-container {
    padding: 0;
  }
}

@import "./consts";

@mixin scrollbar-primary($color, $size, $vertical: true) {
  // webkit scrollbar
  &::-webkit-scrollbar {
    @if $vertical == true {
      width: $size;
    } @else {
      height: $size;
    }
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color;
    border-radius: 8px;
  }
  // webkit scrollbar
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-desktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

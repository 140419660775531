/*
 * FORM FIELD
 */

// remove bottom margin used from the mat-error or mat-hint
.thin-form-field .mat-form-field-wrapper {
  margin-bottom: -1.25em !important;
}
// make the form field expand to fit the container width
.full-width .mat-form-field-wrapper {
  display: block !important;
  width: 100% !important;
  flex-grow: 1 !important;

  &-my-2 {
    margin: 8px 0;
  }

  &-my-4 {
    margin: 32px 0;
  }
}

// *OVERRIDE*
// form field has white background color
.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white !important;
}

.mat-dialog-container {
  padding: 0px !important;
  overflow: hidden !important;
}

.mat-dialog-actions {
  min-height: auto !important;
  margin-bottom: 0 !important;
  box-sizing: border-box !important;
}
